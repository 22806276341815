nav {
  right: 1rem;
  top: 50%;
  padding: 1rem 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  flex-direction: column-reverse;
  width: 60px;
  height: 80%;
  backdrop-filter: blur(50px);
  transform: translateY(-50%);
  z-index: 101;
  border-radius: 30px;
  max-width: 80rem;
  box-shadow: -2px 0 2px rgba(0, 0, 0, 0.2);
}

.logo-icon {
  width: 2.5rem;
}

nav .menu {
  font-size: 1.2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
}

nav .menu a:hover {
  transition: 200ms;
  font-weight: bold;
  transform: scale(1.3);
}

.toggler {
  display: flex;
  align-items: center;
  border: none;
  color: var(--title-color);
  cursor: pointer;
}

.toggler button:hover {
  transition: 200ms;
  transform: scale(1.3);
}

.active-nav {
  font-weight: bold;
  transition: 200ms;
  border-radius: 100%;
}

@media screen and (max-width: 940px) {
  nav {
    width: 90%;
    height: 50px;
    bottom: 1rem;
    flex-direction: row;
    justify-content: space-between;
    transform: translate(-50%);
    left: 50%;
    top: unset;
    box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2);
  }

  nav .menu {
    flex-direction: row;
  }

  .logo-icon {
    width: 2rem;
  }
}
