.primary-button {
  color: var(--title-color);
  padding: 8px 15px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  text-decoration: none;
  cursor: pointer;
}

.secondary-button {
  padding: 8px 15px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  text-decoration: none;
  color: rgb(111, 111, 111);
}

.floating-btn-sm {
  box-shadow: none !important;
}
