@import url("https://ui.saurabhchirde.com/components/v1.0.0/components.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap");

:root {
  --primary-color: #192b52;
  --theme-background-color: #f2f2f24c;
  --theme-primary-color: #16284f;
  --theme-background-color: #181d29;
  --theme-primary-color: #e8e8e8;
}

:root[data-theme="dark"] {
  --theme-background-color: #141517 !important;
  --theme-primary-color: #e8e8e8;
  --title-color: rgb(241, 241, 241);
  --subtitle-color: rgb(192, 192, 192);
  --btn-hover: rgba(242, 242, 242, 0.148);
  --card-bg: #0a0a0a4b;
  --card-border: rgb(79, 79, 79);
  --nav-bar-shadow: rgba(45, 45, 45, 0.726);
}

:root[data-theme="light"] {
  --theme-background-color: #f8f8f8;
  --theme-primary-color: #16284f;
  --title-color: rgb(34, 34, 34);
  --subtitle-color: rgb(95, 95, 95);
  --btn-hover: rgba(29, 29, 29, 0.146);
  --card-bg: rgba(255, 255, 255, 0.144);
  --card-border: rgb(210, 210, 210);
  --nav-bar-shadow: rgba(24, 24, 24, 0.195);
}

* {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  background-color: var(--theme-background-color);
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--theme-background-color);
}

.body {
  min-height: 80vh;
}

.row {
  margin: auto;
  display: flex;
}

a {
  text-decoration: none;
  color: var(--title-color);
}

body.darkTheme {
  background-color: var(--theme-background-color);
  color: var(--theme-primary-color);
}

hr {
  width: 3%;
  margin-top: -0.5rem;
  border-style: none;
  border-top: 2px solid;
  color: var(--title-color);
}

.blog-box {
  display: flex;
  gap: 0;
}

section p {
  padding: 15px 0;
  font-size: 1.1rem;
  color: rgb(154, 154, 154);
}

small {
  font-size: 0.9rem;
}

.practice {
  text-decoration: none;
}

hr {
  max-width: 80%;
}

.cursor-icon {
  margin: auto;
  width: 100%;
  display: none;
}

.cursor-icon img {
  margin: 1rem;
  width: 48%;
}

.row {
  display: flex;
}

.box {
  margin: 1rem 6rem 1rem 1rem;
  width: 48%;
  border: 0 solid gray;
  font-size: 1.4rem;
  padding: 3rem 0;
  background-color: #0479540f;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  color: var(--primary-color);
}

*::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  background-color: var(--primary-color);
}

*::-webkit-scrollbar-track {
  background: transparent;
}

@media screen and (max-width: 768px) {
  .section-dark {
    max-width: 80%;
    display: block;
    background-color: #001f60f3;
    color: rgb(227, 227, 227);
    text-align: center;
    border-radius: 10px;
  }

  hr {
    width: 8%;
  }

  section {
    width: 100%;
  }

  .section-dark {
    max-width: 90%;
  }

  section p {
    color: rgb(154, 154, 154);
    font-size: 1rem;
  }

  hr {
    max-width: 90%;
  }

  .box {
    display: none;
  }
}
