footer {
  text-align: center;
  color: var(--title-color);
  max-width: 100%;
  margin: 1rem auto 2rem;
  padding: 1px 0;
  z-index: 1;
  position: relative;
}

footer h3 {
  font-size: 1.1rem;
}

footer ul {
  display: block;
  padding: 0 0;
  font-size: 1rem;
}

footer i {
  padding: 0.6rem;
  border-radius: 50%;
  font-size: 1.2rem;
  margin: 0 5px;
}

footer i:hover {
  background-color: var(--btn-hover);
  transition: 300ms;
}
