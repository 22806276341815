.with-live,
.without-live {
  width: 100%;
  padding: 0.5rem;
  background-color: var(--card-bg);
  border-radius: 20px;
  backdrop-filter: blur(50px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.106);
  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
}

.section-hide {
  opacity: 0;
  transform: translateY(8rem);
}

.with-live {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.without-live img {
  width: 25rem;
  max-height: 15rem;
  border-radius: 4px;
}

.main-projects img {
  margin: 0.5rem 2.3rem;
}

.other-projects img {
  border: 1px solid var(--card-border);
}

.other-projects {
  flex-direction: column;
  max-width: 23rem;
}

.card-text-section {
  display: flex;
  flex-direction: column;
}

.card-text-section p {
  width: 100%;
  margin-left: 0;
}

.card-button-section {
  margin: 0.6rem 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
  justify-content: center;
}

.other-projects .tech {
  padding: 1.5rem 0 0;
  min-height: 12rem;
}

.other-projects .card-button-section {
  justify-content: space-between;
}

.width-9 {
  width: 9rem;
}

.p-16 {
  padding: 16px;
}

@media screen and (max-width: 768px) {
  .with-live,
  .without-live {
    width: 100%;
    margin: 0 0 0.6rem;
    flex-direction: column;
    align-items: center;
  }

  .without-live {
    box-shadow: none;
  }

  .card-button-section {
    justify-content: space-between;
  }
}

@media screen and (max-width: 440px) {
  .with-live img,
  .without-live img {
    width: 19rem;
  }
}
