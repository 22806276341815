.home-page {
  width: 100%;
}

.home-projects-title {
  text-align: center;
  font-weight: 500;
  padding: 1rem;
}

.home-page-header {
  padding: 3rem 2rem;
  width: 80%;
  margin: auto;
  box-sizing: border-box;
  text-align: center;
  color: var(--title-color);
  font-size: 24px;
  font-weight: bold;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.section-title {
  max-width: 80%;
  display: block;
  text-align: center;
  margin: auto;
  padding-bottom: 20px;
  color: var(--title-color);
  font-size: 20px;
  font-weight: 400;
}

.about-page {
  width: 80%;
  margin: 3rem auto 2rem;
  color: var(--title-color);
}

.about-page img {
  max-width: 15rem;
  width: 50%;
  margin-right: 3.5rem;
  border-radius: 50%;
}

.about-page p {
  font-size: 1.2rem;
}

.about-section {
  width: 100%;
  margin: 4rem auto;
  display: flex;
  align-items: center;
}

.skill-section {
  width: 100%;
  margin: 4rem auto;
}

.section-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tech {
  padding: 0.6rem 2rem;
  color: var(--title-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 940px) {
  .home-page-header {
    margin: 2rem auto 6rem;
    padding: 1rem 1rem;
    height: 100%;
  }

  .project-section {
    width: 95%;
    margin: 3rem auto;
    display: flex;
    flex-direction: row;
  }

  .skill-section {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .about-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }

  .about-page img {
    max-width: 12rem;
    width: 50%;
    margin-right: 0;
    border-radius: 50%;
  }

  .project-section {
    width: 90%;
    margin: 2rem auto;
    gap: 1rem;
  }

  .tech {
    padding: 0.6rem 1rem;
  }
}

@media screen and (max-width: 440px) {
  .home-page-header {
    padding: 1rem 0;
    width: 90%;
    margin: 2rem auto 6rem;
    box-sizing: border-box;
    text-align: center;
    color: var(--title-color);
  }

  .about-page {
    width: 95;
  }

  section .tech {
    padding: 1.5rem 0rem 0;
    align-items: center;
  }
}
